import { createApp } from 'vue'
import { createWebHashHistory, createRouter } from 'vue-router'
import { authGuard } from "@auth0/auth0-vue";

import App from './App.vue'
import HomeView from './HomeView.vue'
import DashboardView from './DashboardView.vue'
// import FeedbackView from './FeedbackView.vue'
import NewsletterEditor from './components/NewsletterEditor.vue'
import NewsletterCreator from './components/NewsletterCreator.vue'
import AdminView from './AdminView.vue'

import { auth0 } from './utils/auth0'

const routes = [
    { path: '/', component: HomeView },
    { path: '/dashboard', component: DashboardView, beforeEnter: authGuard },
    { path: '/newsletter/:id', component: NewsletterEditor, beforeEnter: authGuard },
    { path: '/newsletter/create', component: NewsletterCreator, beforeEnter: authGuard },
    { path: '/admin', component: AdminView, beforeEnter: authGuard }
    // { path: '/feedback', component: FeedbackView, beforeEnter: authGuard }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

createApp(App).use(router).use(auth0).mount('#app')
