const baseURL = process.env.VUE_APP_SERVER_BASE_URL;

import { auth0 } from './auth0'

// Helper function to handle fetch errors
const handleError = (error) => {
  console.error('Error:', error);
  // You can add additional error handling logic here
};

// Function to fetch the access token
const getAccessToken = async () => {
  const token = await auth0.getAccessTokenSilently();
  return token;
};

// Authenticated fetch function
export const myAuthenticatedFetch = async (endpoint, options = {}) => {
  try {
    const token = await getAccessToken();
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(`${baseURL}${endpoint}`, {
      ...options,
      headers,
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`${response.status} ${response.statusText}`);
    }
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Fetch function without authentication (same as before)
export const myFetch = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${baseURL}${endpoint}`, options);

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`${response.status} ${response.statusText}`);
    }
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const mySubmitForm = async (endpoint, data) => {
  return myAuthenticatedFetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const myPost = async (endpoint, data) => {
  return myAuthenticatedFetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const myGet = async (endpoint) => {
  return myAuthenticatedFetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

export const myPut = async (endpoint, data) => {
  return myAuthenticatedFetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const myDelete = async (endpoint) => {
  return myAuthenticatedFetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

export const myPatch = async (endpoint, data) => {
  return myAuthenticatedFetch(endpoint, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}