<script setup>
import { ref, onMounted, computed } from 'vue';
import { myGet, myPost, myDelete, myPut, myPatch } from './utils/api';
import SchedulerMonitor from './components/admin/SchedulerMonitor';

// User management state
const users = ref([]);
const loading = ref(true);
const error = ref(null);
const actionInProgress = ref(false);

// Newsletter reports state
const reports = ref([]);
const reportsLoading = ref(false);
const reportsError = ref(null);
const selectedReport = ref(null);
const filterReady = ref(null);
const searchQuery = ref('');

// Computed filtered reports
const filteredReports = computed(() => {
  return reports.value.filter(report => {
    const matchesReady = filterReady.value === null || report.ready === filterReady.value;
    const matchesSearch = !searchQuery.value || 
      report.report_content.toLowerCase().includes(searchQuery.value.toLowerCase());
    return matchesReady && matchesSearch;
  });
});

// Newsletter report methods
const fetchReports = async () => {
  reportsLoading.value = true;
  reportsError.value = null;
  try {
    const response = await myGet('/api/admin/newsletter-reports');
    reports.value = response.reports.sort((a, b) => 
      new Date(b.report_time) - new Date(a.report_time)
    );
  } catch (err) {
    reportsError.value = 'Failed to fetch reports. Please try again.';
    console.error('Error fetching reports:', err);
  } finally {
    reportsLoading.value = false;
  }
};

const updateReportStatus = async (reportId, ready) => {
  try {
    await myPatch(`/api/admin/newsletter-reports/${reportId}/ready`, { ready });
    await fetchReports();
  } catch (err) {
    reportsError.value = 'Failed to update report status.';
    console.error('Error updating report status:', err);
  }
};

const updateReportContent = async (reportId, subject, content) => {
  try {
    await myPut(`/api/admin/newsletter-reports/${reportId}`, { 
      subject: subject, report_content: content });
    await fetchReports();
  } catch (err) {
    reportsError.value = 'Failed to update report content.';
    console.error('Error updating report:', err);
  }
};

const deleteReport = async (reportId) => {
  if (!confirm('Are you sure you want to delete this report?')) return;
  
  try {
    await myDelete(`/api/admin/newsletter-reports/${reportId}`);
    await fetchReports();
    if (selectedReport.value?.id === reportId) {
      selectedReport.value = null;
    }
  } catch (err) {
    reportsError.value = 'Failed to delete report.';
    console.error('Error deleting report:', err);
  }
};

// User management methods
const fetchUsers = async () => {
  loading.value = true;
  error.value = null;
  try {
    const response = await myGet('/api/admin/list_users');
    users.value = response.users;
  } catch (err) {
    if (err.response && err.response.status === 403) {
      error.value = 'Access denied. You do not have admin privileges.';
    } else {
      error.value = 'Failed to fetch users. Please try again later.';
    }
    console.error('Error fetching users:', err);
  } finally {
    loading.value = false;
  }
};

const toggleUserStatus = async (userId, activate) => {
  if (actionInProgress.value) return;
  
  actionInProgress.value = true;
  error.value = null;
  try {
    const endpoint = activate ? `/api/admin/activate_user/${userId}` : `/api/admin/deactivate_user/${userId}`;
    await myPost(endpoint, {});
    await fetchUsers();
  } catch (err) {
    if (err.response && err.response.status === 400 && err.response.data.detail === "Cannot deactivate your own account") {
      error.value = "You cannot deactivate your own account.";
    } else {
      error.value = `Failed to ${activate ? 'activate' : 'deactivate'} user. Please try again.`;
    }
    console.error('Error toggling user status:', err);
  } finally {
    actionInProgress.value = false;
  }
};

const deleteTestUsers = async () => {
  if (!confirm('Are you sure you want to delete all test users? This action cannot be undone.')) {
    return;
  }
  
  if (actionInProgress.value) return;
  
  actionInProgress.value = true;
  error.value = null;
  try {
    const response = await myDelete('/api/admin/delete_test_users');
    alert(response.message);
    await fetchUsers();
  } catch (err) {
    if (err.response?.data?.detail) {
      error.value = err.response.data.detail;
    } else {
      error.value = 'Failed to delete test users. Please try again.';
    }
    console.error('Error deleting test users:', err);
  } finally {
    actionInProgress.value = false;
  }
};

const testAlerting = async () => {
  try {
    await myPost('/api/admin/test-alerting', {});
  } catch (err) {
    console.error('Error sending test alert:', err);
  }
};

const testEmailing = async () => {
  try {
    await myPost('/api/admin/test-emailing', {});
  } catch (err) {
    console.error('Error sending test email:', err);
  }
};

// Active tab management
const activeTab = ref('reports'); // 'reports' or 'users'

onMounted(() => {
  fetchReports();
  fetchUsers();
});
</script>

<template>
  <div class="admin-view">
    <h1>Admin Panel</h1>

    <button @click="testAlerting">Test Alerting</button>
    <button @click="testEmailing">Test Emailing</button>
    <SchedulerMonitor />
    
    <div class="tabs">
      <button 
        :class="{ active: activeTab === 'reports' }" 
        @click="activeTab = 'reports'"
      >
        Review Newsletter Reports
      </button>
      <button 
        :class="{ active: activeTab === 'users' }" 
        @click="activeTab = 'users'"
      >
        Manage Users
      </button>
    </div>

    <!-- Newsletter Reports Tab -->
    <div v-if="activeTab === 'reports'" class="reports-section">
      <div class="filters">
        <div class="search">
          <input 
            v-model="searchQuery" 
            placeholder="Search reports..." 
            type="text"
          >
        </div>
        <div class="status-filter">
          <select v-model="filterReady">
            <option :value="null">All Status</option>
            <option :value="true">Ready</option>
            <option :value="false">Not Ready</option>
          </select>
        </div>
        <button @click="fetchReports" :disabled="reportsLoading">
          Refresh
        </button>
      </div>

      <div v-if="reportsError" class="error">{{ reportsError }}</div>
      
      <div class="reports-container">
        <div class="reports-list">
          <div v-if="reportsLoading" class="loading">Loading reports...</div>
          <table v-else>
            <thead>
              <tr>
                <th>Report Time</th>
                <th>Status</th>
                <th>Version</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="report in filteredReports" 
                :key="report.id"
                :class="{ selected: selectedReport?.id === report.id }"
              >
                <td>{{ new Date(report.report_time).toLocaleString() }}</td>
                <td>{{ report.ready ? 'Ready' : 'Not Ready' }}</td>
                <td>v{{ report.version }}</td>
                <td>
                  <button @click="selectedReport = report">View</button>
                  <button 
                    @click="updateReportStatus(report.id, !report.ready)"
                    :class="report.ready ? 'reject-button' : 'approve-button'"
                  >
                    {{ report.ready ? 'Mark Not Ready' : 'Mark Ready' }}
                  </button>
                  <button 
                    @click="deleteReport(report.id)"
                    class="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="selectedReport" class="report-detail">
          <h3>Report Details</h3>
          <label for="report-subject">Subject</label>
          <input 
            v-model="selectedReport.subject"
            type="text"
            class="report-subject"
          >
          <label for="report-content">Content:</label>
          <textarea 
            v-model="selectedReport.report_content"
            rows="10"
            class="report-content"
          ></textarea>
          <div class="report-actions">
            <button 
              @click="updateReportContent(selectedReport.id, selectedReport.subject, selectedReport.report_content)"
              class="save-button"
            >
              Save Changes
            </button>
            <button 
              @click="selectedReport = null"
              class="cancel-button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- User Management Tab -->
    <div v-if="activeTab === 'users'" class="user-management">
      <div class="action-bar">
        <button @click="fetchUsers" :disabled="loading" class="refresh-button">
          Refresh User List
        </button>
        <button @click="deleteTestUsers" :disabled="actionInProgress" class="danger-button">
          Delete Test Users
        </button>
      </div>
      <div v-if="loading" class="loading">Loading users...</div>
      <div v-if="error" class="error">{{ error }}</div>
      <div v-if="users.length > 0">
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.email }}</td>
              <td>{{ user.enabled ? 'Active' : 'Inactive' }}</td>
              <td>
                <button 
                  v-if="!user.enabled" 
                  @click="toggleUserStatus(user.id, true)"
                  :disabled="actionInProgress"
                  class="activate-button"
                >
                  Activate
                </button>
                <button 
                  v-else 
                  @click="toggleUserStatus(user.id, false)"
                  :disabled="actionInProgress"
                  class="deactivate-button"
                >
                  Deactivate
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.admin-view {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
}

.tabs button.active {
  background: #007bff;
  color: white;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search input {
  padding: 8px;
  width: 200px;
}

.reports-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.report-detail {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 4px;
}

.report-content {
  width: 95%;
  margin: 10px 0;
  padding: 10px;
  height: 600px;
}

.report-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr.selected {
  background-color: #e3f2fd;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
  border: none;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.loading, .error {
  text-align: center;
  margin-top: 20px;
}

.error {
  color: red;
}

.refresh-button, .save-button {
  background-color: #4CAF50;
  color: white;
}

.activate-button, .approve-button {
  background-color: #4CAF50;
  color: white;
}

.deactivate-button, .reject-button {
  background-color: #f44336;
  color: white;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.danger-button {
  background-color: #dc3545;
  color: white;
}

.danger-button:hover:not(:disabled) {
  background-color: #c82333;
}
</style>
