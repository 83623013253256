<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { myGet, myPost, myAuthenticatedFetch } from '@/utils/api'

const router = useRouter()
const newsletters = ref([])
const error = ref(null)
const loading = ref(true)

const fetchNewsletters = async () => {
    try {
        loading.value = true
        const response = await myGet('/api/newsletters/')
        newsletters.value = response.newsletters
    } catch (err) {
        error.value = err.message
        console.error(err)
    } finally {
        loading.value = false
    }
}

const editNewsletter = (id) => {
    router.push(`/newsletter/${id}`)
}

const toggleNewsletter = async (id, currentStatus) => {
    try {
        loading.value = true
        const endpoint = currentStatus ? `/api/newsletters/${id}/deactivate` : `/api/newsletters/${id}/activate`
        await myPost(endpoint)
        await fetchNewsletters()
    } catch (err) {
        error.value = 'Failed to toggle newsletter status'
        console.error(err)
    } finally {
        loading.value = false
    }
}

const deleteNewsletter = async (id) => {
    if (confirm('Are you sure you want to delete this newsletter?')) {
        try {
            loading.value = true
            await myAuthenticatedFetch(`/api/newsletters/${id}`, { method: 'DELETE' })
            await fetchNewsletters()
        } catch (err) {
            error.value = 'Failed to delete newsletter'
            console.error(err)
        } finally {
            loading.value = false
        }
    }
}

onMounted(fetchNewsletters)
</script>

<template>
  <div class="newsletter-dashboard">
    <h2>Newsletter Dashboard</h2>
    <div v-if="error" class="error-message">{{ error }}</div>
    <div v-if="loading" class="loading-message">Loading newsletters...</div>
    <div v-else-if="newsletters.length === 0" class="no-data-message">No newsletters available</div>
    <div v-else class="newsletter-grid">
      <div v-for="newsletter in newsletters" :key="newsletter.id" class="newsletter-card">
        <h3 class="light-background">{{ newsletter.title }}</h3>
        <p>{{ newsletter.content.substring(0, 100) }}...</p>
        <div class="button-group">
          <button @click="editNewsletter(newsletter.id)" class="edit-button">Edit</button>
          <button @click="toggleNewsletter(newsletter.id, newsletter.activated)" class="toggle-button">
            {{ newsletter.activated ? 'Deactivate' : 'Activate' }}
          </button>
          <button @click="deleteNewsletter(newsletter.id)" class="delete-button">Delete</button>
        </div>
      </div>
    </div>
    <div class="create-button-container">
      <button @click="router.push('/newsletter/create')" class="create-button">Create New Newsletter</button>
    </div>
  </div>
</template>

<style scoped>
.newsletter-dashboard {
  padding: 2rem;
}

.error-message,
.loading-message,
.no-data-message {
  text-align: center;
  margin-top: 2rem;
  color: var(--text-color);
}

.newsletter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.newsletter-card {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.newsletter-card h3 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.edit-button,
.toggle-button,
.delete-button,
.create-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button {
  background-color: var(--accent-color);
  color: var(--text-color);
}

.toggle-button {
  background-color: var(--accent-color);
  color: var(--text-color);
}

.delete-button {
  background-color: var(--accent-color);
  color: white;
}

.create-button-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.create-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 1rem;
}

.edit-button:hover,
.toggle-button:hover,
.delete-button:hover,
.create-button:hover {
  opacity: 0.9;
}
</style>