<script setup>
import { ref } from 'vue'
import { myPost } from '../../utils/api'

const schedulerRunning = ref(false)
const loading = ref({
    gather: false,
    refine: false,
    build: false
})

const toggleScheduler = async () => {
    try {
        const endpoint = schedulerRunning.value ? '/api/admin/scheduler/stop' : '/api/admin/scheduler/start'
        const response = await myPost(endpoint)
        console.log(response.message)
        schedulerRunning.value = !schedulerRunning.value
    } catch (err) {
        console.error('Error toggling scheduler:', err)
    }
}

const cancelAllScheduled = async () => {
    try {
        const response = await myPost('/api/admin/scheduler/cancel-all')
        console.log(response.message)
    } catch (err) {
        console.error('Error cancelling all scheduled tasks:', err)
    }
}

const runSchedulableProcess = async (processName) => {
    const loadingKey = {
        'gather-events': 'gather',
        'refine-events': 'refine',
        'build-newsletters': 'build'
    }[processName]
    
    loading.value[loadingKey] = true
    try {
        const response = await myPost(`/api/admin/scheduler/run-task/${processName}`)
        console.log(response.message)
    } catch (err) {
        console.error('Error running process:', err)
    } finally {
        loading.value[loadingKey] = false
    }
}
</script>

<template>
    <div class="scheduler">
        <div class="scheduler-header">
            <h2>Task Management</h2>
            <div class="scheduler-controls">
                <button 
                    @click="toggleScheduler()"
                    :class="{ 'active': schedulerRunning }"
                    class="control-button"
                >
                    {{ schedulerRunning ? 'Stop Scheduler' : 'Start Scheduler' }}
                </button>
                <button 
                    @click="cancelAllScheduled()"
                    class="control-button cancel"
                >
                    Cancel All Tasks
                </button>
            </div>
        </div>

        <div class="task-buttons">
            <button 
                @click="runSchedulableProcess('gather-events')"
                :disabled="loading.gather"
                class="task-button gather"
            >
                {{ loading.gather ? 'Finding Events...' : 'Find Events' }}
            </button>
            
            <button 
                @click="runSchedulableProcess('refine-events')"
                :disabled="loading.refine"
                class="task-button refine"
            >
                {{ loading.refine ? 'Refining Events...' : 'Refine Events' }}
            </button>
            
            <button 
                @click="runSchedulableProcess('build-newsletters')"
                :disabled="loading.build"
                class="task-button build"
            >
                {{ loading.build ? 'Building Newsletters...' : 'Build Newsletters' }}
            </button>
        </div>

        <div class="schedule-info">
            <h3>Schedule Information</h3>
            <ul>
                <li>Find Events: Every Tuesday at 12:00 AM</li>
                <li>Refine Events: Every Tuesday at 1:00 AM</li>
                <li>Build Newsletters: Every Wednesday at 12:00 AM</li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.scheduler {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
}

.scheduler-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.scheduler-controls {
    display: flex;
    gap: 10px;
}

.control-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #6c757d;
    color: white;
}

.control-button.active {
    background-color: #28a745;
}

.control-button.cancel {
    background-color: #dc3545;
}

.task-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.task-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    min-width: 150px;
    transition: background-color 0.2s;
}

.task-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.gather {
    background-color: #007bff;
}

.gather:hover:not(:disabled) {
    background-color: #0056b3;
}

.refine {
    background-color: #28a745;
}

.refine:hover:not(:disabled) {
    background-color: #218838;
}

.build {
    background-color: #17a2b8;
}

.build:hover:not(:disabled) {
    background-color: #138496;
}

.schedule-info {
    background: white;
    padding: 15px;
    border-radius: 4px;
    margin-top: 20px;
}

.schedule-info h3 {
    margin-top: 0;
    color: #495057;
}

.schedule-info ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.schedule-info li {
    padding: 8px 0;
    color: #6c757d;
    border-bottom: 1px solid #e9ecef;
}

.schedule-info li:last-child {
    border-bottom: none;
}
</style>
