<script setup>
import { computed } from 'vue';

// eslint-disable-next-line
const props = defineProps(['report']);

const formattedDate = computed(() => {
    if (!props.report || !props.report.report_time) return 'N/A';
    const utcDate = new Date(props.report.report_time + 'Z'); // Append 'Z' to indicate UTC time
    return utcDate.toLocaleString();
});

const statusClass = computed(() => {
  if (!props.report) return '';
  return props.report.ready ? 'status-ready' : 'status-pending';
});
</script>

<template>
  <div v-if="props.report" class="newsletter-report">
    <div class="report-header">
      <div class="report-title">
        <h2>Report {{ props.report.version ? `v${props.report.version}` : '' }}</h2>
        <div class="report-subject">{{ props.report.subject }}</div>
      </div>
      <span :class="['status-indicator', statusClass]">
        {{ props.report.ready ? 'Ready' : 'Pending' }}
      </span>
    </div>
    <p class="report-time">Generated on: {{ formattedDate }}</p>
    <pre v-if="props.report.content" class="report-content">{{ props.report.content }}</pre>
    <p v-else class="no-content">No content available</p>
  </div>
  <div v-else class="newsletter-report error">
    <p>Error: Report data is missing or invalid</p>
  </div>
</template>

<style scoped>
.newsletter-report {
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.report-title {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.report-subject {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 500;
}

h2 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--text-color);
}

.status-indicator {
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}

.status-ready {
  background-color: #4caf50;
  color: white;
}

.status-pending {
  background-color: #ffa000;
  color: white;
}

.report-time {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
  margin-bottom: 1rem;
}

.report-content {
  background-color: var(--background-color);
  border-radius: 4px;
  padding: 1rem;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 0.9rem;
  line-height: 1.5;
  max-height: 300px;
  overflow-y: auto;
  width: 95%;
  height: 600px;
}

.no-content {
  font-style: italic;
  color: var(--text-color-secondary);
}

.error {
  background-color: #ffcdd2;
  color: #b71c1c;
}
</style>
