<script setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { myGet, myPut, myPost } from '@/utils/api'
import NewsletterReport from './NewsletterReport.vue'
import EventList from './EventList.vue'

const route = useRoute()
const router = useRouter()
const error = ref(null)
const loading = ref(true)
const formData = reactive({
  title: '',
  content: '',
  instructions: '',
  location: ''
})

const newsletter_events = ref([])

const newsletterId = route.params.id
const report = ref(null)
const reports = ref([])

watch (() => reports, (newReports) => {
  reports.value = newReports
})

const isGeneratingPreview = ref(false)
const previewProgress = ref(0)

const fetchNewsletter = async () => {
  try {
    const response = await myGet(`/api/newsletters/${newsletterId}`)
    Object.assign(formData, response)
    loading.value = false
  } catch (err) {
    error.value = 'Failed to load newsletter data'
    console.error(err)
    loading.value = false
  }
  try {
    fetchNewsletterReports()
  }
  catch (err) {
    error.value = 'Failed to load newsletter reports'
    console.error(err)
  }
  try {
    fetchNewsletterEvents()
  }
  catch (err) {
    error.value = 'Failed to load newsletter events'
    console.error(err)
  }
}

const fetchNewsletterReports = async () => {
  try {
    const response = await myGet(`/api/newsletters/${newsletterId}/reports`)
    if (response.reports.length === 0) {
      return
    }
    reports.value = response.reports
  } catch (err) {
    error.value = 'Failed to load newsletter reports'
    console.error(err)
  }
}

const fetchNewsletterEvents = async () => {
  try {
    const response = await myGet(`/api/newsletters/${newsletterId}/events?limit=15`)
    newsletter_events.value = response.events
  } catch (err) {
    error.value = 'Failed to load newsletter events'
    console.error(err)
  }
}

const submitForm = async () => {
  try {
    await myPut(`/api/newsletters/${newsletterId}`, formData)
  } catch (err) {
    error.value = 'An error occurred while updating the newsletter'
    console.error(err)
  }
}

const generateLivePreview = async () => {
  isGeneratingPreview.value = true
  error.value = null
  report.value = null
  previewProgress.value = 0

  try {
    const response = await myPost(`/api/newsletters/${newsletterId}/live_preview`, formData)
    const taskId = response.task_id
    await pollTaskStatus(taskId)
  } catch (e) {
    error.value = 'Failed to generate report. Please try again.'
    console.error(e)
  }
}

const pollTaskStatus = async (taskId) => {
  const pollInterval = 2000 // Poll every 2 seconds
  const minutes = 5
  const maxAttempts = minutes * (60 / 2) // Maximum number of polling attempts, 2 seconds per attempt
  let attempts = 0

  const poll = async () => {
    try {
      const response = await myGet(`/api/tasks/${taskId}`)

      if (response.status === 'completed') {
        isGeneratingPreview.value = false
        // Fetch the report using the result (report ID) from the task
        await fetchNewsletterReports()
        await fetchNewsletterEvents()
      } else if (attempts < maxAttempts) {
        attempts++
        setTimeout(poll, pollInterval)
      } else {
        throw new Error('Timeout: Report generation is taking longer than expected')
      }
    } catch (e) {
      error.value = `Failed to generate report: ${e.message}`
      console.error(e)
    }
  }

  await poll()
}

onMounted(fetchNewsletter)
</script>

<template>
  <div class="newsletter-editor">
    <h1>Edit Newsletter</h1>
    <div v-if="error" class="error-message">{{ error }}</div>
    <div v-if="loading" class="loading-message">Loading newsletter data...</div>
    <template v-else>
      <form @submit.prevent="submitForm" class="editor-form">
        <div class="form-group">
          <label for="title">Title</label>
          <input type="text" v-model="formData.title" placeholder="Newsletter Title" required class="form-input">
        </div>
        <div class="form-group">
          <label for="content">Content</label>
          <textarea v-model="formData.content" placeholder="What kind of events are you interested in?" required class="form-textarea"></textarea>
        </div>
        <div class="form-group">
          <label for="instructions">Instructions</label>
          <textarea v-model="formData.instructions" placeholder="Any special custom instructions?" class="form-textarea"></textarea>
        </div>
        <div class="form-group location-group">
          <label for="location">Location</label>
          <input type="text" v-model="formData.location" placeholder="Location" class="form-input">
        </div>
        <div class="button-group">
          <button type="submit" class="submit-button">Update Newsletter</button>
          <button @click.prevent="router.push('/dashboard')" class="cancel-button">Cancel</button>
        </div>
      </form>
    </template>
    
    <div class="live-preview-section">
      <button @click="generateLivePreview" :disabled="isGeneratingPreview" class="live-preview-button">
        {{ isGeneratingPreview ? 'Generating...' : 'Generate Live Preview' }}
      </button>
      
      <div v-if="error" class="error-message">{{ error }}</div>
      
      <div v-if="isGeneratingPreview" class="loading-indicator">
        <p>Generating preview</p>
        <div class="progress-window">
        </div>
      </div>
      
      <div v-if="report" class="report-preview">
        <h3>Live Preview</h3>
        <NewsletterReport :report="report" />
      </div>
    </div>

    <div v-if="newsletter_events.length > 0">
      <EventList :events="newsletter_events" />
    </div>
    
    <div v-if="reports.length > 0">
      <h1>History</h1>
      <div v-for="my_report in reports" :key="my_report.id">
        <NewsletterReport :report="my_report" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.newsletter-editor {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1, h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.error-message,
.loading-message {
  text-align: center;
  margin-bottom: 1rem;
}

.error-message {
  color: #e74c3c;
}

.editor-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.form-textarea {
  min-height: 150px;
  resize: vertical;
}

.location-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-checkbox {
  margin-right: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.submit-button,
.cancel-button,
.live-preview-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submit-button,
.live-preview-button {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.cancel-button {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.submit-button:hover,
.cancel-button:hover,
.live-preview-button:hover {
  opacity: 0.9;
}

.live-preview-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.live-preview-section {
  margin-top: 2rem;
}

.loading-indicator {
  text-align: center;
  margin-top: 1rem;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.report-preview {
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--secondary-color);
  border-radius: 4px;
}

.report-preview pre {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
