<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { myPost } from '@/utils/api'

const router = useRouter()
const error = ref(null)
const formData = reactive({
  title: '',
  content: '',
  instructions: '',
  location: ''
})

const submitForm = async () => {
  try {
    await myPost('/api/newsletters/', formData)
    router.push('/dashboard')
  } catch (err) {
    error.value = 'An error occurred while creating the newsletter'
    console.error(err)
  }
}
</script>

<template>
  <div class="newsletter-creator">
    <h1>Create New Newsletter</h1>
    <div v-if="error" class="error-message">{{ error }}</div>
    <form @submit.prevent="submitForm" class="creator-form">
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text" v-model="formData.title" placeholder="Newsletter Title" required class="form-input">
      </div>
      <div class="form-group">
        <label for="content">Content</label>
        <textarea v-model="formData.content" placeholder="What kind of events are you interested in? Who are you and what do you like?" required class="form-textarea"></textarea>
      </div>
      <div class="form-group">
        <label for="instructions">Instructions</label>
        <textarea v-model="formData.instructions" placeholder="Any special custom instructions?" class="form-textarea"></textarea>
      </div>
      <div class="form-group location-group">
        <label for="location">Location</label>
        <input type="text" v-model="formData.location" placeholder="Location" class="form-input">
      </div>
      <div class="button-group">
        <button type="submit" class="submit-button">Create Newsletter</button>
        <button @click.prevent="router.push('/dashboard')" class="cancel-button">Cancel</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.newsletter-creator {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.error-message {
  color: #e74c3c;
  text-align: center;
  margin-bottom: 1rem;
}

.creator-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.form-textarea {
  min-height: 150px;
  resize: vertical;
}

.location-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-checkbox {
  margin-right: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.submit-button,
.cancel-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.cancel-button {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.submit-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}
</style>
