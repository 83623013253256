<script setup>
import { RouterLink, RouterView } from 'vue-router';
import LoginButton from "./components/buttons/login-button.vue";
import LogoutButton from "./components/buttons/logout-button.vue";
import SignupButton from "./components/buttons/signup-button.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import '@/assets/main.css';

const { isAuthenticated } = useAuth0();
</script>

<template>
  <div id="app">
    <header>
      <div class="container">
        <h1>Lets Do Agency</h1>
        <nav>
          <RouterLink to="/">Home</RouterLink>
          <div class="nav-bar__buttons">
            <template v-if="!isAuthenticated">
              <SignupButton />
              <LoginButton />
            </template>
            <template v-if="isAuthenticated">
              <RouterLink to="/dashboard">Dashboard</RouterLink>
              <!-- <RouterLink to="/feedback">Feedback</RouterLink> -->
              <LogoutButton />
            </template>
          </div>
        </nav>
      </div>
    </header>
    <main>
      <div class="container">
        <RouterView />
      </div>
    </main>
    <footer>
      <div class="container">
        <p>Copyright 2024 Lets Do Agency. All rights reserved.</p>
	<br />
	<p>Report bugs, make suggestions or come chat with us at <a class="light-background" href="https://discord.gg/USvSkFyFpj">Discord</a></p>
      </div>
    </footer>
  </div>
</template>

<style>
@import '@/assets/main.css';

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  padding: 1rem 0;
}

header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav {
  display: flex;
  gap: 1rem;
}

main {
  flex-grow: 1;
  padding: 2rem 0;
}

footer {
  background-color: var(--secondary-color);
  padding: 1rem 0;
  margin-top: auto;
}

.nav-bar__buttons {
  display: flex;
  gap: 0.5rem;
}
</style>
