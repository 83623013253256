<!-- https://developer.auth0.com/resources/guides/spa/vue/basic-authentication#configure-a-vue-js-authentication-plugin -->
<template>
  <button class="button__login" @click="handleLogin">Log In</button>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { loginWithRedirect } = useAuth0();

const handleLogin = () => {
  loginWithRedirect({
    appState: {
      target: "/",
    }
  });
};
</script>