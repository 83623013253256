<script setup>
import { ref, watch } from 'vue'

// eslint-disable-next-line
const props = defineProps({
  events: {
    type: Array,
    default: () => []
  }
})

const showEventsList = ref(true)
const localEvents = ref(props.events)
const toggleEventsList = () => {
  showEventsList.value = !showEventsList.value
}

watch(() => props.events, (newEvents) => {
  localEvents.value = newEvents
})
// 
// const fetchEvents = () => {
//   // This function is now only used if no events are provided
//   // Simulated API call
//   setTimeout(() => {
//     localEvents.value = [
//       {
//         id: 1,
//         name: 'Tech Conference 2023',
//         date: 'October 15-17, 2023',
//         location: 'San Francisco, CA',
//         description: 'Annual gathering of tech innovators and industry leaders.'
//       },
//       {
//         id: 2,
//         name: 'Art in the Park',
//         date: 'Every Saturday, 9 AM - 2 PM',
//         location: 'Central Park, New York City',
//         description: 'Weekly outdoor art exhibition featuring local artists.'
//       },
//       {
//         id: 3,
//         name: 'International Food Festival',
//         date: 'November 5-7, 2023',
//         location: 'Chicago, IL',
//         description: 'Explore cuisines from around the world in this 3-day event.'
//       }
//     ]
//   }, 1000)
// }
// 
// onMounted(() => {
//   if (localEvents.value.length === 0) {
//     fetchEvents()
//   }
// })
</script>

<template>
  <div class="event-list">
    <h3>Events in Report</h3>
    <button @click="toggleEventsList">Toggle Events List</button>
    <div v-if="showEventsList && localEvents.length > 0">
      <div v-for="event in localEvents" :key="event.id" class="event-item">
        <!-- Lets create a form for each event so we can update it -->
         <!-- events have: title, url, date, time, location, description, organizer, cost -->
        <h4>{{ event.title }}</h4>
        <p>{{ event.date }} - {{ event.time }}</p>
        <p>{{ event.location }}</p>
        <p>{{ event.description }}</p>
        <p>Organizer: {{ event.organizer }}</p>
        <p>Cost: {{ event.cost }}</p>
        <p v-if="event.url != 'Unknown'"><a v-bind:href="event.url" target="_blank" rel="noopener noreferrer">{{ event.url }}</a></p>
      </div>
    </div>
    <p v-else>No events in the report.</p>
  </div>
</template>

<style scoped>
.event-list {
  margin-top: 20px;
}

.event-item {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
}

h4 {
  margin-top: 0;
}
</style>