<script setup>
</script>

<template>
<div>
    <h1>How does this work?</h1>
    <p>You describe the events you want to see in natural language, and we'll send you a newsletter with those events.</p>
    <p>For example, you could say "I like to see events about contemporary art in New York City" or "I want to listen to jazz in Chicago" or "I want to know about any career fairs in my area" or "I want to attend open source tech conferences anywhere in the United States, and I need to know at least 3 months ahead of time".</p>
    <p>Why is it called "Lets Do Agency"? Because we're a team of agents that help you do things. We're like a secret agency that helps you get things done.</p>
    <p>This agency is made up of a cooperative multi-agent system that includes AI agents and human agents. The AI agents help you describe the events you want to see in natural language, and the human agents help you refine your request and make sure you get the best results.</p>
    <p>That's a fancy way of saying we have a team of AI that seek out events for you and a team of humans that make sure the AI is doing a good job.</p>
    <p>To that end, you can give us feedback on the newsletters we send you. This feedback is used to train the AI agents to do a better job.</p>
</div>
</template>

<style>
</style>